import {
  modifyNewItem,
  getBlockChildrenDeep,
  addParentToBlocksSubflow,
  filterRecordOutOfTreeById,
  editNameOnTree,
  addRecordOnTree,
} from "../../utils/BlockUtils/BlockUtils";
import {
  GET_BLOCK,
  GET_FAVORITE_BLOCKS,
  DELETE_BLOCK,
  UPDATE_BLOCKS_REQUEST,
  UPDATE_BLOCKS_SUCESS,
  UPDATE_BLOCKS_ERROR,
  GET_BLOCKS_REQUEST,
  GET_BLOCKS_SUCCESS,
  GET_BLOCKS_ERROR,
  GET_BLOCKS_SUBFLOW_REQUEST,
  GET_BLOCKS_SUBFLOW_SUCCESS,
  GET_BLOCKS_SUBFLOW_ERROR,
  GET_FAVORITE_BLOCKS_REQUEST,
  GET_FAVORITE_BLOCKS_SUCCESS,
  GET_FAVORITE_BLOCKS_ERROR,
  MOVE_BLOCK_REQUEST,
  MOVE_BLOCK_SUCCESS,
  MOVE_BLOCK_ERROR,
  COPY_BLOCKS_REQUEST,
  COPY_BLOCKS_SUCCESS,
  CLEAR_BLOCK,
  CLEAR_BLOCKS,
  CLEAR_BLOCKS_WITH_SUBFLOW,
  GET_BLOCKS_DEPS_REQUEST,
  GET_BLOCKS_DEPS_SUCCESS,
  GET_BLOCKS_DEPS_ERROR,
  ADD_BLOCK_REQUEST,
  ADD_BLOCK_SUCCESS,
  ADD_BLOCK_ERROR,
  UPDATE_BLOCK_REQUEST,
  UPDATE_BLOCK_SUCCESS,
  UPDATE_BLOCK_ERROR,
  UPDATE_BLOCK_DATA_REQUEST,
  UPDATE_BLOCK_DATA_SUCCESS,
  UPDATE_BLOCK_DATA_ERROR,
  UPDATE_BLOCK_PROGRESS_REQUEST,
  UPDATE_BLOCK_PROGRESS_SUCCESS,
  UPDATE_BLOCK_PROGRESS_ERROR,
  UPDATE_BLOCKS_SUCCESS,
  CONVERT_BLOCK_ACTIVITY_TO_BLOCK,
  UPDATE_BLOCK_ACTIVITY,
  GET_GRID_BLOCKS_REQUEST,
  GET_GRID_BLOCKS_SUCCESS,
  DELETE_ACTIVITY_ON_MAPPING_GRID,
  DELETE_BLOCK_ON_MAPPING_GRID,
  UPDATE_NAME_ON_MAPPING_GRID,
  ADD_RECORD_ON_MAPPING_GRID,
} from "../types";
import {
  updateItemInArray,
  mergeArrays,
} from "../../utils/FilterUtils/FilterUtils";

const initialState = {
  blocks: [],
  blocksSubflow: [],
  accumBlocks: [],
  accumBlocksSubflow: [],
  blocksWithDeps: [],
  favoriteBlocks: [],
  gridBlocks: [],
  block: {},
  isFetchingGrid: false,
  isFetching: false,
  isFetchingSubflow: false,
  isCopying: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_BLOCK:
      return {
        ...state,
        block: payload,
      };
    case ADD_BLOCK_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_BLOCK_SUCCESS:
      const newItemSubflow = modifyNewItem(payload.block, payload.blockType);

      return {
        ...state,
        blocks: [...state.blocks, payload.block],
        blocksSubflow: [...state.blocksSubflow, newItemSubflow],
        accumBlocks: payload.accumulate
          ? [...state.accumBlocks, payload.block]
          : [],
        accumBlocksSubflow: payload.accumulate
          ? [...state.accumBlocksSubflow, newItemSubflow]
          : [],
        isFetching: false,
      };
    case ADD_BLOCK_ERROR:
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    case UPDATE_BLOCK_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_BLOCK_SUCCESS:
      return {
        ...state,
        blocks: updateItemInArray(state.blocks, payload),
        accumBlocks: updateItemInArray(state.accumBlocks, payload),
        isFetching: false,
      };
    case UPDATE_BLOCK_ERROR:
      return {
        ...state,
        error: payload,
      };
    case UPDATE_BLOCK_DATA_REQUEST:
      return state;
    case UPDATE_BLOCK_DATA_SUCCESS:
      return {
        ...state,
        blocks: updateItemInArray(state.blocks, payload),
        accumBlocks: updateItemInArray(state.accumBlocks, payload),
      };
    case UPDATE_BLOCK_DATA_ERROR:
      return {
        ...state,
        error: payload,
      };
    case UPDATE_BLOCK_PROGRESS_REQUEST:
      return state;
    case UPDATE_BLOCK_PROGRESS_SUCCESS:
      const children = getBlockChildrenDeep(state.blocks, payload.id);

      return {
        ...state,
        blocks: state.blocks.map((item) => {
          if (item.id === payload.id || children.includes(item.id)) {
            item.progress = payload.progress;
          }
          return item;
        }),
      };
    case UPDATE_BLOCK_PROGRESS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case GET_BLOCKS_REQUEST:
      return {
        ...state,
        isFetching: true,
        blocks: [],
      };
    case GET_BLOCKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        blocks: payload.blocks,
        accumBlocks: payload.accumulate
          ? mergeArrays(state.accumBlocks, payload.blocks)
          : [],
      };
    case GET_BLOCKS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_GRID_BLOCKS_REQUEST:
      return { ...state, isFetchingGrid: true };
    case GET_GRID_BLOCKS_SUCCESS:
      return { ...state, gridBlocks: payload, isFetchingGrid: false };
    case GET_BLOCKS_SUBFLOW_REQUEST:
      return {
        ...state,
        isFetchingSubflow: true,
        blocksSubflow: [],
      };
    case GET_BLOCKS_SUBFLOW_SUCCESS:
      return {
        ...state,
        isFetchingSubflow: false,
        blocksSubflow: payload.blocks || [],
        accumBlocksSubflow: payload.accumulate
          ? mergeArrays(
              state.accumBlocksSubflow,
              addParentToBlocksSubflow(payload.blocks || [], payload.parentId)
            )
          : [],
      };
    case GET_BLOCKS_SUBFLOW_ERROR:
      return {
        ...state,
        isFetchingSubflow: false,
        error: payload,
      };
    case GET_BLOCKS_DEPS_REQUEST:
      return {
        ...state,
        isFetching: true,
        blocksWithDeps: [],
      };
    case GET_BLOCKS_DEPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        blocksWithDeps: payload,
      };
    case GET_BLOCKS_DEPS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_FAVORITE_BLOCKS_REQUEST:
      return {
        ...state,
        isFetching: true,
        favoriteBlocks: [],
      };
    case GET_FAVORITE_BLOCKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        favoriteBlocks: payload,
      };
    case GET_FAVORITE_BLOCKS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case UPDATE_BLOCKS_REQUEST:
      return {
        ...state,
        isFetching: false,
        error: "",
      };
    case UPDATE_BLOCKS_SUCCESS:
      return {
        ...state,
        blocks: payload,
        isFetching: false,
      };
    case COPY_BLOCKS_REQUEST:
      return {
        ...state,
        //isCopying: true,
      };
    case COPY_BLOCKS_SUCCESS:
      /*const newBlockList = state.blocks;
      newBlockList.concat(payload);*/
      return {
        ...state,
        // isCopying: false,
      };
    case UPDATE_BLOCKS_ERROR:
      return {
        ...state,
        isCopying: false,
        error: payload,
      };
    case GET_FAVORITE_BLOCKS:
      return {
        ...state,
        favoriteBlocks: payload,
      };

    case DELETE_BLOCK:
      return {
        ...state,
        blocks: state.blocks.filter((block) => !payload.includes(+block.id)),
        blocksSubflow: state.blocksSubflow.filter(
          (block) => !payload.includes(+block.id)
        ),
        accumBlocks: state.accumBlocks.filter(
          (block) => !payload.includes(+block.id)
        ),
        accumBlocksSubflow: state.accumBlocksSubflow.filter(
          (block) => !payload.includes(+block.id)
        ),
      };
    case MOVE_BLOCK_REQUEST:
      return {
        ...state,
      };
    case MOVE_BLOCK_SUCCESS:
      const tmpMoveBlocks = state.blocks.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
      return {
        ...state,
        blocks: tmpMoveBlocks,
      };
    case MOVE_BLOCK_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CONVERT_BLOCK_ACTIVITY_TO_BLOCK:
      return {
        ...state,
        blocks: state.blocks.map((block) => {
          if (block.id === payload.id) {
            block.activity = null;
          }
          return block;
        }),
        accumBlocks: state.accumBlocks.map((block) => {
          if (block.id === payload.id) {
            block.activity = null;
          }
          return block;
        }),
      };
    case UPDATE_BLOCK_ACTIVITY:
      return {
        ...state,
        blocks: state.blocks.map((block) => {
          if (block.activity?.id === payload.id) {
            block.activity = payload;
          }
          return block;
        }),
        accumBlocks: state.accumBlocks.map((block) => {
          if (block.activity?.id === payload.id) {
            block.activity = payload;
          }
          return block;
        }),
      };
    case CLEAR_BLOCKS:
      return {
        ...state,
        blocks: [],
      };
    case CLEAR_BLOCK:
      return {
        ...state,
        block: {},
      };
    case CLEAR_BLOCKS_WITH_SUBFLOW:
      return {
        ...state,
        blocksSubflow: [],
      };
    case DELETE_ACTIVITY_ON_MAPPING_GRID:
    case DELETE_BLOCK_ON_MAPPING_GRID:
      return {
        ...state,
        gridBlocks: filterRecordOutOfTreeById(state.gridBlocks, payload),
      };
    case UPDATE_NAME_ON_MAPPING_GRID:
      return {
        ...state,
        gridBlocks: editNameOnTree(state.gridBlocks, payload.id, payload.name),
      };
    case ADD_RECORD_ON_MAPPING_GRID:
      return {
        ...state,
        gridBlocks: addRecordOnTree(
          state.gridBlocks,
          payload.parentId,
          payload.record
        ),
      };
    default:
      return state;
  }
}
